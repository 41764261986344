import React, { useState } from 'react';
import styled from 'styled-components/macro';
import InputField from './InputField/InputField';
import Select from './Select/Select';
import Checkbox from './Checkbox/Checkbox';
import Textarea from './Textarea/Textarea';
import Datepicker from './Datepicker';
import File from './File';
import Radio from './Radio';
import InputPrice from './InputPrice';
import InputTime from './InputTime';
import Text from '../Text';
import { Flex } from '../../Ui';
import ArrowDown from '../../DefaultIcons/ArrowDown';
import { BsInfoCircle } from 'react-icons/bs';
import ReactSelect from 'react-select';
const BoxInput = styled.div`
  display: flex;
  flex: 1;
`;
const Box = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.bg_grey_light};
  padding: 0 5px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  color: ${({ theme }) => theme.secondary};
  white-space: nowrap;
`;
const InputContainer = styled.div`
  margin-bottom: 20px;
  margin-left: ${props => props.checkbox && '45px'};
  display: block;
  .flexInfo {
    display: flex;
    & > div:first-child {
      flex-grow: 1;
    }
  }

  .info {
    cursor: pointer;
    background-color: ${({ type }) => type !== 'checkbox' && type !== 'radio' && '#f4f6f9'};
    padding: 0 5px;
    display: flex;
    align-items: center;
    svg {
      color: #808080;
    }
  }
`;

const ErrorContainer = styled.p`
  margin-top: 5px;
  text-align: right;
  color: red;
  font-size: 12px;
`;

const TextP = styled.p`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  margin-top: 24px;
`;
const ReactSelectStyle = styled(ReactSelect)`
  width: 100%;
  border-radius: 15px;

  border: 1px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.transparent
        ? 'transparent'
        : 'transparent';
    }};

  .select__indicators {
    display: none;
  }

  .select__control {
    box-shadow: none;
    &:hover {
      border: none;
    }
  }
`;
const Input = props => {
  let label = null;
  let inputElement = null;
  let title = null;

  const [startDate, setStartDate] = useState(null);

  if (props.title) {
    title = (
      <Text capit as='p' size={13} bold margin='10px 0 5px 5px' text_box>
        {props.title}
      </Text>
    );
  }
  if (props.label) {
    label = (
      <Text
        className='label'
        capit
        as='p'
        size={20}
        margin='0'
        style={{ marginBottom: '5px', color: '#666666' }}
      >
        {props.label}
      </Text>
    );
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <InputField
          {...props.elementConfig}
          onChange={props.changed}
          {...props}
          value={props.value}
          readOnly={props.readonly}
          {...props}
        />
      );
      break;
    case 'select':
      let options = null;
      if (props.isCountry) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt?.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.countries.map(opt => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isDistrict) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.districts.map(opt => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isCity) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.cities.map(opt => (
              <option value={opt.name} key={opt.name}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } /* else if (props.isArea) {
        options = (
          <>
            {props.elementConfig?.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props?.catalogs?.map((opt, i) => (
              <option value={opt?.id} key={`${opt?.id}`}>
                {opt?.name}
              </option>
            ))}
          </>
        );
      }  */ else if (props.isMacrocategoria) {
        options = (
          <>
            {props.elementConfig?.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props?.macroCategories?.map((opt, i) => (
              <option value={opt?.code} key={`${opt?.description}-${opt?.code}`}>
                {opt?.description || opt?.descriprion}
              </option>
            ))}
          </>
        );
      } else if (props.isCategories) {
        options = (
          <>
            {props.elementConfig?.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props?.categories?.map((opt, i) => (
              <option value={opt?.description} key={`${opt?.description}`}>
                {opt?.description}
              </option>
            ))}
          </>
        );
      } else if (props.isBrands) {
        options = (
          <>
            {props.elementConfig?.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt?.displayValue}
              </option>
            ))}

            {props?.brands?.map((opt, i) => (
              <option value={opt?.code} key={`${opt?.code}-${i}`}>
                {opt?.description}
              </option>
            ))}
          </>
        );
      } else if (props.isPdv) {
        // options1=props.elementConfig.options.map(opt=>opt.displayValue)
        /*         options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.pdv.map(opt => (
              <option value={opt.store} key={opt.id}>
                {opt.store}
              </option>
            ))}
          </>
        ); */
        options = props.pdv.map(opt => ({ label: opt.store, value: opt.store }));
      } else {
        options = props.elementConfig.options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.displayValue}
          </option>
        ));
      }
      inputElement = props.isPdv ? (
        <ReactSelectStyle
          className='basic-single'
          classNamePrefix='select'
          isDisabled={props.elementConfig.disabled}
          //isLoading={isLoading}
          isClearable={true}
          isSearchable={true}
          onChange={props.changed}
          name={props.elementConfig.name}
          options={options}
          placeholder=''
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: '70px',
              borderRadius: '15px',
              border: 'none',
            }),
          }}
          {...props}
        />
      ) : (
        <Select
          onChange={props.changed}
          name={props.elementConfig.name}
          disabled={props.elementConfig.disabled}
          {...props}
        >
          {options}
        </Select>
      );
      break;
    case 'datepicker':
      inputElement = (
        <Datepicker
          lang={props.lang}
          {...props.elementConfig}
          selected={props.startdate}
          onChange={props.changedDatePicker}
          minDate={
            props.validation.minDate
              ? props.validation.minDate === 'today'
                ? new Date()
                : new Date(props.validation.minDate)
              : null
          }
          {...props}
          maxDate={
            props.validation.maxDate
              ? props.validation.maxDate === 'today'
                ? new Date()
                : new Date(props.validation.maxDate)
              : null
          }
          {...props}
        />
      );
      break;
    case 'inputcheckbox':
      inputElement = (
        <Checkbox
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          onClick={() => (props.readonly ? 'return false;' : '')}
          {...props}
        />
      );
      break;
    case 'inputradio':
      inputElement = (
        <Radio
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          readonly={props.readonly}
          {...props}
        />
      );
      break;
    case 'inputfile':
      inputElement = (
        <File
          {...props.elementConfig}
          handleFile={props.fileUploadHandler}
          imagePreviewUrl={props.imagePreviewUrl}
          deleteFileHandler={props.deleteFileHandler}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'price':
      inputElement = (
        <InputPrice
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'inputtime':
      inputElement = (
        <InputTime
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <Textarea
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    default:
      inputElement = <input />;
  }
  return (
    <React.Fragment>
      {title}
      <InputContainer
        type={props.elementConfig?.type}
        className={
          props.elementConfig?.type === 'checkbox' ? 'check_contact ' + props.name : props.name
        }
        checkbox={props.elementConfig?.type === 'checkbox'}
      >
        <div className='flexInfo'>
          <div className={props.name}>
            {label && props.elementConfig?.type !== 'checkbox' ? label : null}
            <BoxInput className={props.elementType == 'select' ? 'inputSelect' : ''}>
              {inputElement}
              {props.elementType == 'select' && <ArrowDown />}
            </BoxInput>
          </div>
          {props.hint && (
            <div className='info'>
              <BsInfoCircle onClick={() => props.clickHintHandler(props.hint)} />
            </div>
          )}
        </div>
        <ErrorContainer>
          {(!props.isvalid && !props.istouched && props.firstSubmit && props.showError) ||
        
          (!props.isvalid && props.showError)
            ? props.error
            : ''}
        </ErrorContainer>
      </InputContainer>
    </React.Fragment>
  );
};

export default Input;
