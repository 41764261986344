import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Col, Flex, Button, Text, Modal, Loader } from '../Ui';
import Fade from '../Fade';
import moment from 'moment';
import parse from 'html-react-parser';
import Fuse from 'fuse.js';
import Input from '../Ui/Input/InputField/InputField';
import { searchBy } from '../../utils/utils';
const CustomSearch = styled.div`
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  & > div {
    padding: 0;
    border: none;
  }
  input {
    height: 40px;
  }
  svg {
    height: 40px;
  }
`;
function Plays() {
  const plays = useSelector(state => state.user?.user?.plays);
  const [search, setSearch] = useState('');
  const [slideRows, setSlideRows] = useState([]);
  useEffect(() => {
    setSlideRows(plays);
  }, [plays]);
  useEffect(() => {
    if (search.length > 0) {
      setSlideRows(searchBy(['game', 'promotion', 'datetime', 'code'], plays, search));
    } else {
      setSlideRows(plays);
    }
  }, [search]);
  /*   useEffect(() => {
    const fuse = new Fuse(slideRows, {
      keys: ['game', 'promotion', 'datetime', 'code'],
      useExtendedSearch: true,
      includeMatches: true,
    });
    const result = fuse.search(search).map(({ item }) => item);

    if (search.length > 0 && result.length > 0 && slideRows.length > 0) {
      setSlideRows(result);
    } else {
      setSlideRows(plays);
    }
  }, [search]); */

  return (
    <>
      <Fade>
        <CustomSearch>
          <Input
            icon='search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Ricerca'
          />
        </CustomSearch>
        {slideRows?.map((win, winIndex) => (
          <Box key={winIndex} className='mb-20'>
            <Flex direction='column' justufy='center' style={{ padding: '15px' }}>
              {win.promotion && (
                <Flex className='mb-10'>
                  <Text bold size={18} capit text_box>
                    Concorso:
                  </Text>
                  <Text capit style={{ marginLeft: '5px' }} text_box>
                    {parse(win.promotion)}
                  </Text>
                </Flex>
              )}
              <Flex className='mb-10'>
                <Text bold size={18} capit text_box>
                  Data giocata:
                </Text>
                <Text capit style={{ marginLeft: '5px' }} text_box>
                  {win.datetime}
                </Text>
              </Flex>
              {win.preinteraction_code && (
                <Flex>
                  <Text bold size={18} capit text_box>
                    Codice:
                  </Text>
                  <Text capit style={{ marginLeft: '5px' }} text_box>
                    {win.preinteraction_code}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>
        ))}
      </Fade>
    </>
  );
}

export default Plays;
