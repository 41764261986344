import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer/Footer';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import Header from '../../components/Header/Header';
import { Route } from 'react-router-dom';
import SigninForm from '../../components/SigninForm/SigninForm';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';
import AuthText from '../../components/AuthText';
import ViewerSlider from '../../components/ViewerSlider/ViewerSlider';
import HomeBlocks from '../../components/HomeBlocks';
import { useDispatch, useSelector } from 'react-redux';
import { showAuthForm, signinSuccess } from '../../store/actions/auth';
import { useEffect } from 'react';
import useQuery from '../../hooks/useQuery';
import { appQuery } from '../../store/actions/app';
import customAxiosNm from '../../config/axios-refresh-token';

const AuthTemplate = props => {
  // const [isLogin, setIsLogin] = useState(false);

  const { from } = props.location.state || { from: '/' };
  const isLogin = useSelector(state => state.auth.show_auth_form);
  const dispatch = useDispatch();
  const { auth, token } = useParams();
  const query = useQuery();
  const queryApp = query.get('app');
  const open = query.get('open');
  // const token = query.get('token');
  const appQueryParam = useSelector(state => state.app?.appQuery);
  const visible = useSelector(state => state.app?.confg?.visible);
  const history = useHistory();
  const [registerData, setRegisterData] = useState(null);
  useEffect(() => {
    history.location.pathname === '/auth/register' && dispatch(showAuthForm(true));
    if (!token) return;
    customAxiosNm
      .get(`/auth/externaltokendecode?token=${token}`)
      .then(response => {
        setRegisterData(response.data);
        dispatch(showAuthForm(true));
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');
    if (queryApp || localQueryApp) {
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
      localStorage.setItem('queryApp', true);
    }
  }, [queryApp]);
  useEffect(() => {
    visible && history.location.pathname === '/auth/register' && history.push('/auth/login');
  }, [visible, history.location.pathname]);

  return (
    <CustomWrapper>
      {!appQueryParam && <Header />}
      <SidesWrapper>
        <Wrapper>
          <ViewerSlider />
          <AuthText />
          <HomeBlocks />

          {(isLogin || open === 'true') && (
            <FormContainer visible={visible && history.location.pathname === '/auth/register'}>
              <Route path='/auth/login' exact>
                <SigninForm whereToGo={from} />
              </Route>

              <Route path={token ? '/auth/register/:token*' : '/auth/register'} exact>
                <RegisterForm data={registerData} />
              </Route>

              <Route path='/auth/recovery-password' exact>
                <RecoveryPasswordForm />
              </Route>
              {props.children}
            </FormContainer>
          )}
        </Wrapper>
        {!appQueryParam && <Footer />}
      </SidesWrapper>
    </CustomWrapper>
  );
};

const CustomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const SidesWrapper = styled.div`
  display: block;
  /* margin-bottom: 50px; */
  padding: 10px;
  flex-grow: 1;
  //margin-top: 103px;
  position: relative;
  ${respondTo.sm`
    margin-top: 0px;
    padding: 0;
    // margin-left: auto;
    // margin-right: auto;
  `}
`;

const FormContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => '#fff'};
  flex: 0 0 100%;
  padding: 30px 15px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999999999;
  position: absolute;
  right: 20px;
  top: -13px;
  /* border-radius: 5px; */
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  ${respondTo.sm`
    flex: 0 0 400px;
    width: 550px;
  `};
`;

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(AuthTemplate);
