import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    margin-top: 10px;

    cursor: pointer;
    font-size: 14px;
    color: ${({ theme }) => '#000'};
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    & input:checked ~ .checkmark {
      background-color: ${props => props.theme.colore_checkbox_attiva};
      border: ${props => props.theme.colore_checkbox_attiva};
    }

    & .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 25px;
      width: 25px;
      border: 2px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.theme.default_color};
      border-radius: 50%;
      background-color: ${props => props.theme.colore_checkbox_disattiva};
    }
    & .radio_profile {
      display: flex;
      color: #000;
    }
  }
`;

const Radio = props => {
  const { radioInputs } = props;

  return (
    <Container onChange={props.onChange} {...props}>
      {radioInputs.map((radio, indx) => {
        return (
          <label htmlFor={radio.elementConfig.id} key={indx} className='radio_profile'>
            <span>{radio.label}</span>
            <input
              type='radio'
              id={radio.elementConfig.id}
              value={radio.elementConfig.value}
              name={radio.elementConfig.name}
            />
            <span className='checkmark'></span>
          </label>
        );
      })}
    </Container>
  );
};

export default Radio;
