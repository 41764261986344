import styled from 'styled-components';
//import arrow from '../../../../assets/images/arrowDown.svg';
import arrow from '../../../../assets/images/arrowDown.svg';

const Select = styled.select`
  display: block;
  font-size: 14px;
  font-family: sans-serif;
  padding: 0 30px 0 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  outline: 0;
  height: 50px;
  //border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* background-color: ${({ theme, transparent }) => !transparent && theme.input.bg}; */
  //background-image: url(${arrow});
  background-position: right 8px top 17px, 0 0;
  background-repeat: no-repeat;
  background-size: 20px auto, 100%;
  color: ${({ theme }) => theme.colore_testo_campi};
  border: 1px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.transparent
        ? 'transparent'
        : props.theme.input.bg;
    }};

  &::placeholder {
    color: #47545d59;
  }
  cursor: pointer;
`;

export default Select;
