import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Col, Flex, Button, Text, Modal, Loader } from '../Ui';
import Fade from '../Fade';
import moment from 'moment';
import parse from 'html-react-parser';
import Fuse from 'fuse.js';
import Input from '../Ui/Input/InputField/InputField';
import { searchBy } from '../../utils/utils';
const CustomSearch = styled.div`
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  & > div {
    padding: 0;
    border: none;
  }
  input {
    height: 40px;
  }
  svg {
    height: 40px;
  }
`;
function ListaPremi() {
  const wins = useSelector(state => state.user?.user?.wins);
  const [search, setSearch] = useState('');
  const [slideRows, setSlideRows] = useState([]);
  useEffect(() => {
    setSlideRows(
      wins?.map(item => ({
        ...item,
        datetime: moment(item.datetime).format('DD/MM/YYYY'),
      })),
    );
  }, [wins]);

  // useEffect(() => {
  //   if (search.length > 0) {
  //     setSlideRows(
  //       searchBy(
  //         ['game', 'promotion', 'datetime', 'code'],
  //         wins.map(item => ({
  //           ...item,
  //           datetime: moment(item.datetime).format('DD/MM/YYYY'),
  //         })),
  //         search,
  //       ),
  //     );
  //   } else {
  //     setSlideRows(
  //       wins.map(item => ({
  //         ...item,
  //         datetime: moment(item.datetime).format('DD/MM/YYYY'),
  //       })),
  //     );
  //   }
  // }, [search]);

  return (
    <>
      <Fade>
        {/* <CustomSearch>
          <Input
            icon='search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Ricerca'
          />
        </CustomSearch> */}
        {slideRows?.map((win, winIndex) => (
          <Box key={winIndex} className='mb-20'>
            <Flex align='center'>
              <Col width={22} alignResp='center' align='left'>
                <img src={win.prize_image} alt='' />
              </Col>
              <Col width={70}>
                {win.promotion && (
                  <Flex className='mb-10'>
                    <Text bold size={18} capit text_box>
                      Concorso:
                    </Text>
                    <Text capit style={{ marginLeft: '5px' }} text_box>
                      {parse(win.promotion)}
                    </Text>
                  </Flex>
                )}

                <Flex className='mb-10'>
                  <Text bold size={18} capit text_box>
                    Data vincita:
                  </Text>
                  <Text capit style={{ marginLeft: '5px' }} text_box>
                    {win.datetime}
                  </Text>
                </Flex>
                {Boolean(win.showcode) ? (
                  <Flex className='mb-10'>
                    <Text upper text_box bold>
                      Codice:
                    </Text>
                    {win?.code_voucher ? (
                      <a
                        href={win.code_voucher}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ marginLeft: '5px' }}
                      >
                        Scarica il buono
                      </a>
                    ) : (
                      <Text type='bigTitle' size={18} bold style={{ marginLeft: '5px' }} text_box>
                        {win.code}
                      </Text>
                    )}
                  </Flex>
                ) : null}
                {Boolean(win.status) && (
                  <Flex className='mb-10'>
                    <Text upper text_box bold>
                      stato:
                    </Text>
                    <Text type='bigTitle' size={18} bold style={{ marginLeft: '5px' }} text_box>
                      {win.status}
                    </Text>
                  </Flex>
                )}
                <img src={win.prize_barcode} />
              </Col>
            </Flex>
          </Box>
        ))}
      </Fade>
    </>
  );
}

export default ListaPremi;
