import React from 'react';
import styled, { css } from 'styled-components';
import parse from 'html-react-parser';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 1px;
  opacity: 0;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    align-items: flex-start;
    color: #000;
    &:before {
      content: '';
      flex: 0 0 24px;
      height: 24px;
      margin-right: 10px;
      display: inline-block;
      border-radius: ${({ theme }) => theme.border_radius_generale_piccolo || 0};
      border: 2px solid
        ${props =>
          (!props.isvalid && props.istouched && props.firstSubmit) ||
          (!props.isvalid && props.firstSubmit)
            ? props.theme.input.error
            : props.value === 1
            ? props.theme.colore_checkbox_attiva
            : props.theme.default_color};
      /*   background-color: ${props =>
        props.value === 1
          ? props.theme.colore_checkbox_attiva
          : props.theme.colore_checkbox_disattiva}; */
      transition: all 150ms;
    }

    &:after {
      ${({ value }) =>
        value === 1 &&
        css`
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: ${props => 'solid ' + props.theme.colore_checkbox_attiva};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        `}
    }
    a {
      color: ${props => props.theme.primary};
      font-weight: 700;
      text-decoration: underline;
    }

    .span-label {
      margin-top: 4px;

      & * {
        font-size: 16px !important;
      }
    }
  }
`;

const Checkbox = props => {
  return (
    <CheckboxContainer
      isvalid={props.isvalid}
      istouched={props.istouched}
      firstSubmit={props.firstSubmit}
      value={parseInt(props.value)}
    >
      <label htmlFor={props.name}>
        <span className='span-label'>{parse(props.label || '')}</span>
      </label>

      <HiddenCheckbox {...props} />
    </CheckboxContainer>
  );
};

export default Checkbox;
