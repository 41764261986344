import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

const ButtonWrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${props => (props.loading || props.disabled ? 'default' : 'pointer')};
  outline: none;
  //border: ${({ border, theme }) => (border ? '1px solid ' + theme.bordersColor.btn : 'none')};
  border: ${({ border, theme }) =>
    border ? '1px solid ' + theme.colore_bordo_box : '1px solid ' + theme.bordo_bottone};
  border-radius: ${({ theme }) => theme.border_radius_generale_piccolo};
  color: ${({ color, theme, secondary }) =>
    secondary
      ? theme.colore_testo_bottoni_secondari
      : color
      ? color
      : theme.colore_testo_bottoni || theme.default_text_color};
  padding: ${({ padding }) => (padding ? padding : '0 25px')};
  background-color: ${props =>
    props.active
      ? props.theme.bg_bottoni || props.theme.primary
      : props.secondary
      ? props.theme.bg_bottoni_secondari
      : '#fe0218'};

  transition: all 0.3s;
  min-width: 250px;
  height: ${({ height }) => (height ? height : '50px')};
  font-size: ${({ size }) => (size ? size + 'px' : '20px')};
  /* font-weight: 700; */
  text-transform: ${props => (props.upper ? 'uppercase' : '')};
  opacity: ${props => (props.loading || props.disabled ? '.5' : '1')};
  width: ${props => (props.fullwidth ? '100%' : props.width ? props.width : 'auto')};
  line-height: 1;
  gap: 5px;
  &:hover {
    opacity: 0.9;
  }
  a {
    color: ${({ active }) => (active ? 'inherit' : '#fff')};
  }
  svg {
    margin-left: auto;
    font-size: 35px;
    color: ${({ theme }) => theme.color_icon_prd};
    margin-left: 26px;
  }
  span {
    margin-right: 5px;
    color: ${({ active }) => active && 'inherit'};
    font-size: 18px;
  }
`;

const Button = forwardRef((props, ref) => {
  return (
    <ButtonWrap {...props} ref={ref}>
      {props.loading ? (
        <Loader show={true} inBtn white={props.white ? true : false} />
      ) : (
        props.children
      )}
    </ButtonWrap>
  );
});

Button.defaultProps = {
  upper: false,
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool,
  loading: PropTypes.bool,
  white: PropTypes.bool,
};

export default Button;
